import store from '@/store';
import staticPermission from '@/config/staticPermission.json';

/**
 * Check if the current user has permission to operate on the given data
 * @param {Object} data - The data object to check permission for
 * @param {String} module - The module name (e.g., 'firmEnterprise')
 * @param {String} operation - The operation type (e.g., 'edit', 'delete')
 * @returns {Boolean} - Whether the user has permission to operate on the data
 */
export function hasOperationPermission(data, module, operation) {
  const permission = staticPermission;
  const userInfo = store.getters.userInfo;
  const userRole = userInfo.role_name; // Get the user's role
  
  // 如果是管理员角色，直接返回true
  if (userRole && (userRole.includes('administrator') || userRole.includes('admin'))) {
    return true;
  }
  
  // Check if the user has the basic permission for this operation
  // First check module-specific permissions
  let operationPermission;
  if (permission[module] && permission[module][userRole]) {
    operationPermission = permission[module][userRole][`${module}_${operation}`];
    if (!operationPermission) return false;
  } else {
    // If no module-specific permissions found, check global permissions
    if (permission.global && permission.global[userRole]) {
      operationPermission = permission.global[userRole][`${module}_${operation}`];
      if (!operationPermission) return false;
    } else {
      return false; // No permissions found for this user role
    }
  }
  
  // If the operationPermission is "self", only allow operations on own data
  if (operationPermission === "self") { 
    // Check if the data was created by the current user
    const dataCreatorId = data.createUserId || data.createUser || data.userId;
    const currentUserId = userInfo.user_id;
    
    return dataCreatorId === currentUserId;
  }
  
  // If we reached here, the permission is true and not restricted to own data
  return true;
}

/**
 * Filter a list of data to only include items the user has permission to operate on
 * @param {Array} dataList - The list of data objects
 * @param {String} module - The module name (e.g., 'firmEnterprise')
 * @param {String} operation - The operation type (e.g., 'edit', 'delete')
 * @returns {Array} - Filtered list of data objects
 */
export function filterByPermission(dataList, module, operation) {
  if (!Array.isArray(dataList)) return [];
  
  return dataList.filter(item => hasOperationPermission(item, module, operation));
}

/**
 * Check if the current user should only operate on their own data
 * @param {String} module - The module name (e.g., 'firmEnterprise')
 * @param {String} operation - The operation type (e.g., 'select', 'edit')
 * @returns {Boolean} - Whether the user should only operate on their own data
 */
export function shouldOnlyOperateOwnData(module, operation) {
    const permission = staticPermission;
    const userInfo = store.getters.userInfo;
    const userRole = userInfo.role_name; // Get the user's role
    
    let operationPermission;
    
    // Check module-specific permissions
    if (permission[module] && permission[module][userRole]) {
      operationPermission = permission[module][userRole][`${module}_${operation}`];
    }
    
    // If no module-specific permissions found, check global permissions
    if (!operationPermission && permission.global && permission.global[userRole]) {
      operationPermission = permission.global[userRole][`${module}_${operation}`];
    }
    
    // Return true if the permission is "self"
    return operationPermission === "self";
}

/**
 * Add a filter to API search parameters to only return the user's own data
 * @param {Object} searchParams - The search parameters object
 * @param {String} module - The module name (e.g., 'firmEnterprise')
 * @param {String} operation - The operation type (e.g., 'select')
 * @returns {Object} - Updated search parameters
 */
export function addOwnershipFilter(searchParams, module, operation = 'select') {
    const userInfo = store.getters.userInfo;
  
  if (shouldOnlyOperateOwnData(module, operation)) {
    return {
      ...searchParams,
      createUser: userInfo.user_id
    };
  }
  
  return searchParams;
}